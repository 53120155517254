
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content{
    :global{
        display: block;
        margin: 0 auto 0;
        max-width: calc(100vw - 256px);
        // 默认desktop
        .subscribe-email-box{
            display: flex;
            justify-content: space-between;
            background-color: #F3F4F7;
            border-radius: 14px;
            padding: 40px;
            .h5{
                display: none;
            }
            .info-box{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-right: 69px;
                width: 55%;
                html[site='sa'] & {
                    padding-left: 69px;
                    padding-right: 0;
                }
                .title{
                    font-family: var(--ff-woff2-EB);
                    font-size: 28px;
                    line-height: 1.1;
                    color: #000;
                }
                .form-email-box{
                    display: flex;
                    .input-email {
                        display: inline-block;
                        font-family: var(--ff-woff2-M);
                        font-size: 16px;
                        line-height: 1.2;
                        color: #000;
                        width: 75%;
                        background-color: #E6E6E6;
                        padding: 15px;
                        min-height: 49px;
                        outline: none;
                        border: none;
                        vertical-align: middle;
                        border-radius: 4px 0 0 4px;
                        &::placeholder{
                            font-family: var(--ff-woff2-M);
                            font-size: 16px;
                            line-height: 1.2;
                            color: #919191;
                        }
                    }
                    .form-submit{
                        width: 25%;
                        background-color: #000;
                        border-radius: 0 4px 4px 0;
                        text-align: center;
                        padding: 15px 6px;
                        font-family: var(--ff-woff2-B);
                        font-size: 16px;
                        line-height: 1.2;
                        color: #fff;
                        opacity: .9;
                        cursor: pointer;
                        &:hover, &:active, &:focus{
                            opacity: 1;
                        }
                    }
                }
                .message{
                    margin: 8px 0;
                    font-family: var(--ff-woff2-M);
                    font-size: 14px;
                    line-height: 18px;
                    display: flex;
                    gap: 6px;
                    &.success{
                        color: #3F68E0;
                    }
                    &.error{
                        color: #FA4500;
                        .tip-icon{
                            width: 14px;
                            height: 14px;
                            margin-top: 2px;
                        }
                    }
                }
                .agreement{
                    margin-top: 16px;
                    display: inline-flex;
                    gap: 8px;
                    &.tip{
                        margin-top: 0;
                    }
                    .agree-check{
                        font-size: 14px;
                        width: 14px;
                        height: 14px;
                        margin-top: 1px;
                    }
                    .agree-label{
                        font-family: var(--ff-woff2-M);
                        font-size: 12px;
                        line-height: 16px;
                        color: #626262;
                        a{
                            color: #626262;
                            &:hover, &:active, &:focus{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
            .img-box{
                width: 45%;
                height: 273px;
                background-color: gray;
                border-radius: 14px;
                overflow: hidden;
            }
        }

        // > 1440 PC
        @include devices(desktop-max) {
            width: 1200px;
            max-width: 1200px;
            .subscribe-email-box{
                padding: 44px;
                .info-box{
                    padding-right: 108px;
                    html[site='sa'] & {
                        padding-left: 108px;
                        padding-right: 0;
                    }
                    .title{
                        font-size: 34px;
                    }
                    .form-email-box{
                        .input-email{
                            padding: 17px 18px;
                            min-height: 56px;
                            font-size: 18px;
                            &::placeholder{
                                font-size: 18px;
                            }
                        }
                        .form-submit{
                            padding: 17px 6px;
                            font-size: 18px;
                        }
                    }
                    .agreement{
                        .agree-check{
                            font-size: 16px;
                            width: 16px;
                            height: 16px;
                            margin-top: 0;
                        }
                    }
                }
                .img-box{
                    height: 296px;
                }
            }
        }

        // < 1024
        @include devices(pad) {
            max-width: 100%;
            .subscribe-email-box{
                padding: 60px 48px;
                border-radius: 0;
                .info-box{
                    gap: 38px;
                    padding-right: 40px;
                    html[site='sa'] & {
                        padding-left: 40px;
                        padding-right: 0;
                    }
                    .title{
                        font-size: 22px;
                    }
                    .email-info{
                        .form-email-box{
                            flex-direction: column;
                            gap: 14px;
                            .input-email{
                                width: 100%;
                                padding: 14px;
                                min-height: 48px;
                                font-size: 16px;
                                border-radius: 4px;
                                &::placeholder{
                                    font-size: 16px;
                                }
                            }
                            .form-submit{
                                width: 100%;
                                padding: 14px 24px;
                                font-size: 16px;
                                border-radius: 4px;
                            }
                        }
                        .message{
                            margin: 14px 0 0;
                        }
                        .agreement{
                            margin-top: 14px;
                            .agree-check{
                                font-size: 16px;
                                width: 16px;
                                height: 16px;
                                margin-top: 0;
                            }
                        }
                    }
                }
                .img-box{
                    height: 198px;
                }
            }
        }

        // < 768
        @include devices(h5) {
            max-width: 100%;
            .subscribe-email-box{
                padding: 56px 24px;
                gap: 30px;
                flex-direction: column;
                .h5{
                    display: block;
                }
                .pc{
                    display: none;
                }
                .info-box{
                    width: 100%;
                    gap: 30px;
                    padding-right: 0;
                    html[site='sa'] & {
                        padding-left: 0;
                        padding-right: 0;
                    }
                    .title{
                        font-size: 18px;
                        text-align: center;
                    }
                    .email-info{
                        .form-email-box{
                            gap: 16px;
                        }
                        .message{
                            margin: 14px 0 0;
                        }
                    }
                }
                .img-box{
                    width: 100%;
                    height: 198px;
                }
            }
        }
    }
}